import * as openpgp from "openpgp"
import _ from "lodash";
import {WORKER_URL} from "../../utils/constants";


export async function generatePGPKeys(email, passphrase) {
    let {privateKey, publicKey} = await openpgp.generateKey({
        type: 'rsa',
        rsaBits: 2048,
        userIDs: [{email}],
        passphrase,
    });

    privateKey = privateKey.replace(/^\n+/, '').replace(/\n+$/, '')
    publicKey = publicKey.replace(/^\n+/, '').replace(/\n+$/, '')

    return {publicKey, privateKey}
}

export async function encryptPGPMessage(publicKeys, plainText) {
    return new Promise((resolve, reject) => {
        let worker = new Worker(`${WORKER_URL}?t=${new Date().toISOString()}`)
        worker.postMessage({
            action: "encrypt", options: {
                text: plainText,
                publicKeys
            }
        })
        worker.onmessage = function (data) {
            return resolve(data.data)
        }
        worker.onerror = function (error) {
            return reject(error)
        }
    })
}

export async function decryptPGPMessage(privateKeyArmored, passphrase, messageArmored) {
    return new Promise((resolve, reject) => {
        let worker = new Worker(`${WORKER_URL}?t=${new Date().toISOString()}`)
        worker.postMessage({
            action: "decrypt", options: {
                text: messageArmored,
                privateKey: privateKeyArmored,
                passphrase
            }
        })
        worker.onmessage = function (data) {
            return resolve(data.data)
        }
        worker.onerror = function (error) {
            return reject(error)
        }
    })
}

export async function signPGPMessage(privateKeyArmored, passphrase, plainText) {
    const privateKey = await decryptPrivateKey(privateKeyArmored, passphrase)
    const unsignedMessage = await openpgp.createCleartextMessage({text: plainText});
    return openpgp.sign({
        message: unsignedMessage,
        signingKeys: privateKey
    });
}

function readPrivateKey(armoredKey) {
    return openpgp.readPrivateKey({armoredKey})
}

export function readPublicKey(armoredKey) {
    return openpgp.readKey({armoredKey})
}

function createMessage(text) {
    return openpgp.createMessage({text})
}

function readMessage(armoredMessage) {
    let options = {armoredMessage}
    return openpgp.readMessage(options)
}

export async function decryptPrivateKey(armoredKey, passphrase) {
    return openpgp.decryptKey({
        privateKey: await readPrivateKey(armoredKey),
        passphrase
    })
}

export async function getKeyFingerprint(publicKeyArmored) {
    return (await readPublicKey(publicKeyArmored)).getFingerprint().toLowerCase()
}