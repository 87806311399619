import axios from "axios";
import {getEndpoints} from "../utils/server-endpoints";

export function prepareAuthHeader(token) {
    return {Authorization: `Bearer ${token}`}
}

async function sendCall(url, method, data, headers = {}) {
    let resp = await axios({url, method, data, headers})
    return resp.data
}

export async function sessionValid(token) {
    try {
        const {url, method} = getEndpoints().checkSession
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {}, headers)
        return Promise.resolve(data.content)
    } catch (error) {
        return Promise.resolve({})
    }
}

export async function sendLoginRequest(body) {
    const {url, method} = getEndpoints().login
    try {
        let data = await sendCall(url, method, body)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("invalid credentials")
    }
}

export async function sendRegisterRequest(body) {
    try {
        const {url, method} = getEndpoints().register
        await sendCall(url, method, body)
        return Promise.resolve(true)
    } catch (error) {
        console.error(error)
        return Promise.reject("server error occurred")
    }
}

export async function checkUsernameDuplication(username) {
    try {
        const {url, method} = getEndpoints().checkUsernameDuplication
        const data = await sendCall(url, method, {username})
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("server error occurred")
    }
}

export async function logoutCall(token) {
    try {
        const {url, method} = getEndpoints().logout
        const headers = prepareAuthHeader(token)
        await sendCall(url, method, {}, headers)
        return true
    } catch (error) {
        console.error(error)
        return Promise.reject("server error occurred")
    }
}

export async function listing(token, body) {
    try {
        const {url, method} = getEndpoints().emailListing
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function listBoxes(token) {
    try {
        const {url, method} = getEndpoints().boxesListing
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function sendMessageToBackend(body, token) {
    try {
        try {
            const {url, method} = getEndpoints().sendMessageHttp
            const headers = prepareAuthHeader(token)
            let data = await sendCall(url, method, body, headers)
            return data.content
        } catch (error) {
            console.error(error)
            return Promise.reject(error.message)
        }
    } catch (e) {

    }
}

export async function fetchPublicKeys(receivers, token) {
    try {
        const {url, method} = getEndpoints().fetchReceiversPublicKeys
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {receivers}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function fetchMessageBody(uid, box, token) {
    try {
        const {url, method} = getEndpoints().fetchMessageBodyForRender
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {uid, box}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function fetchAttachmentData(token, body) {
    try {
        const {url, method} = getEndpoints().fetchAttachmentData
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject(error.message)
    }
}

export async function listingForCache(body, token) {
    try {
        const {url, method} = getEndpoints().listingForCache
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("Server error occurred.")
    }
}

export async function getMessagesCount(token) {
    try {
        const {url, method} = getEndpoints().getMessagesCount
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("Server error occurred.")
    }
}

export async function markMessageAsReadBackend(token, body) {
    try {
        const {url, method} = getEndpoints().markMessageAsRead
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("Server error occurred.")
    }
}

export async function fetchThread(token, body) {
    try {
        const {url, method} = getEndpoints().fetchThread
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("Server error occurred.")
    }
}

export async function markDeviceSetupComplete(token) {
    try {
        const {url, method} = getEndpoints().markDeviceAsComplete
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {}, headers)
        return data.content
    } catch (error) {
        console.error(error)
        return Promise.reject("Server error occurred.")
    }
}

export async function deleteMessage(token, body) {
    try {
        const {url, method} = getEndpoints().deleteMessage
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function moveMessageOnServer(token, body) {
    try {
        const {url, method} = getEndpoints().moveMessage
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    } catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function deleteFullThreadOnServer(token, body) {
    try{
        const {url, method} = getEndpoints().deleteFullThread
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function addDomain(token, body) {
    try{
        const {url, method} = getEndpoints().addDomain
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}

export async function listDomains(token, body) {
    try{
        const {url, method} = getEndpoints().listDomains
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}


export async function fetchDomain(token, body) {
    try{
        const {url, method} = getEndpoints().fetchDomain
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function deleteDomainOnServer(token, body) {
    try{
        const {url, method} = getEndpoints().deleteDomain
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function updateDomainOnServer(token, body) {
    try{
        const {url, method} = getEndpoints().updateDomain
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}

export async function listAliases(token, body) {
    try{
        const {url, method} = getEndpoints().listAliases
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function addAliasOnServer(token, body) {
    try{
        const {url, method} = getEndpoints().addAlias
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function deleteAliasOnServer(token, body) {
    try{
        const {url, method} = getEndpoints().deleteAlias
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.reject("Server error occurred.")
    }
}

export async function fetchUserAccounts(token) {
    try{
        const {url, method} = getEndpoints().fetchUserAccounts
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, {}, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.resolve()
    }
}

export async function switchLoginOnServer(token, body) {
    try{
        const {url, method} = getEndpoints().switchLogin
        const headers = prepareAuthHeader(token)
        let data = await sendCall(url, method, body, headers)
        return data.content
    }catch (e) {
        console.error(e)
        return Promise.resolve()
    }
}