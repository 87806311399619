import React from 'react';
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import {TOAST_CONTAINER_ID} from "../../utils/constants";

const Header = () => {
    return (
        <>
            <div style={{border: "1px solid grey", height: "5vh"}} className="h-full">
                <h1>Privmail</h1>
            </div>
            <ToastContainer containerId={TOAST_CONTAINER_ID}/>
        </>
    );
};

export default Header;
