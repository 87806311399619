import React, {useEffect, useState} from 'react';
import {useNavigation} from '../../hooks/navigation';
import './index.css';
import {fetchUserAccounts, logoutCall, switchLoginOnServer} from "../../server-calls";
import {toast} from 'react-toastify';
import StateManager from "../../redux/StateManager";
import {TOAST_CONTAINER_ID} from "../../utils/constants";
import {generateOrGetDeviceId} from "../../cookie";

const Navigation = () => {
    let [userAccounts, setUserAccounts] = useState([]);
    const {redirect, reloadPage} = useNavigation();
    const logout = async () => {
        toast.promise(performLogoutOperations(), {
            pending: "Logging you out",
            success: "Log out success",
            error: "Server error occurred"
        }, {containerId: TOAST_CONTAINER_ID}).then(() => {
            redirect("/login")
        })
    }

    async function performLogoutOperations() {
        try {
            await logoutCall(StateManager.getToken())
            StateManager.cleanState()
            return true
        } catch (e) {
            console.error(e)
            return Promise.reject()
        }
    }

    async function startingOperations() {
        userAccounts = await fetchUserAccounts(StateManager.getToken())
        setUserAccounts(userAccounts)
    }

    useEffect(() => {
        startingOperations().catch(e => {
            console.error(e)
        })
    }, []);

    async function handleLoginChange(event) {
        let alias = userAccounts[event.target.value]
        console.log(event, generateOrGetDeviceId())
        try {
            toast.loading("switching account", {containerId: TOAST_CONTAINER_ID})
            let token = await switchLoginOnServer(StateManager.getToken(), {
                aliasId: alias.id,
                deviceId: generateOrGetDeviceId()
            })
            toast.dismiss({containerId: TOAST_CONTAINER_ID})

            StateManager.setIsLoggedIn(true)
            StateManager.setUserEmail(alias.email)
            StateManager.setToken(token)
            reloadPage()
        } catch (e) {
            console.error(e)
            toast.error(e.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }

    }

    return (
        <div style={{border: "1px solid grey", height: "100vh"}} className="w-1/5 bg-gray-200">
            <select onChange={handleLoginChange} style={{width: "100%"}}>
                {userAccounts.map((account, key) => (
                    <option key={key} value={key}>{account.email}</option>
                ))}
            </select>
            <br></br><br></br>
            <ul style={{listStyleType: 'none'}} className="p-0">
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/compose")}>
                    Compose
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/mail")}>
                    Mail
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/domains")}>
                    Domains
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/aliases")}>
                    Aliases
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/filters")}>
                    Filters
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={() => redirect("/settings")}>
                    Settings
                </li>
                <li className="customLi mb-2 p-1" style={{cursor: "pointer"}} onClick={logout}>
                    Logout
                </li>
            </ul>
        </div>
    );
};

export default Navigation;
