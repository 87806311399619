import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "../components/navigation";
import Header from "../components/header";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useState} from "react";
import StandardButton from "../components/basic/StandardButton";
import StandardTextInput from "../components/basic/StandardTextInput";
import Styles from "../styles/main.module.css";
import StandardDropdown from "../components/basic/StandardDropDown";
import StateManager from "../redux/StateManager";
import {DOMAIN_SORT_OPTIONS, DOMAIN_SORT_ORDER, TOAST_CONTAINER_ID} from "../utils/constants";
import {toast} from "react-toastify";
import _ from "lodash";
import {addDomain, deleteDomainOnServer, listDomains, updateDomainOnServer} from "../server-calls";
import {useNavigation} from "../hooks/navigation";

export default function Domains() {
    const {reloadPage} = useNavigation()
    let [showDomainForm, setShowDomainForm] = useState(false);
    let [domains, setDomains] = useState([]);
    let [sortOptions] = useState(DOMAIN_SORT_OPTIONS)
    let [sortOrders] = useState(DOMAIN_SORT_ORDER)
    //form values//
    let [domainValue, setDomainValue] = useState("");
    let [currentPage, setCurrentPage] = useState(1);
    let [totalPages, setTotalPages] = useState(1);
    let [hasNextPage, setHasNextPage] = useState(false);
    let [pageSize] = useState(15);
    let [hasPreviousPage, setHasPreviousPage] = useState(false);
    let [sortOrder, setSortOrder] = useState("asc");
    let [sortOption, setSortOption] = useState("title");
    let [autoAliasHandle, setAutoAliasHandle] = useState(false);
    let [domainEdit, setDomainEdit] = useState(false);
    let [currentDomainShown, setCurrentDomainShown] = useState("");
    let [domainEditAutoAliasHandle, setDomainEditAutoAliasHandle] = useState(false);
    let [searchValue, setSearchValue] = useState("");
    let [searchPerformed, setSearchPerformed] = useState(false);

    function stateUpdateSearchValue(value) {
        searchValue = value
        setSearchValue(searchValue)
    }

    function stateUpdateCurrentDomainShown(value) {
        currentDomainShown = value
        setCurrentDomainShown(currentDomainShown)
    }

    function stateUpdatePaginationData(dmsData) {
        hasNextPage = dmsData.hasNext
        hasPreviousPage = dmsData.hasPrevious
        totalPages = dmsData.totalPages
        setHasNextPage(hasNextPage)
        setHasPreviousPage(hasPreviousPage)
        setTotalPages(totalPages)

    }

    async function startingOperations(search = "") {
        let dmsData = await listDomains(StateManager.getToken(), {
            sortOption: sortOption,
            ascending: sortOrder === "asc",
            pageNumber: currentPage,
            pageSize: pageSize,
            search: search,
            fetchAll: false
        })

        domains = dmsData.domains

        stateUpdatePaginationData(dmsData)

        setDomains(domains)

    }

    useEffect(() => {
        toast.promise(startingOperations(), {
            pending: "loading data",
            error: "server error occurred"
        }, {containerId: TOAST_CONTAINER_ID}).catch((error) => {
            console.error(error)
        })

    }, [sortOrder, sortOptions, currentPage]);

    function stateUpdateDomainAutoAliasHandle(event) {
        autoAliasHandle = event.target.checked
        setAutoAliasHandle(autoAliasHandle)
    }

    function stateUpdateSortOption(option) {
        StateManager.setDomainSortingKey(option)
        sortOption = option
        setSortOption(sortOption)
    }

    function stateUpdateSortOrder(order) {
        StateManager.setDomainSortingOrder(order)
        sortOrder = order;
        setSortOrder(order);
    }

    function nextPage() {
        currentPage = currentPage + 1;
        setCurrentPage(currentPage);
    }

    function previousPage() {
        currentPage = currentPage - 1;
        setCurrentPage(currentPage);
    }

    function stateUpdateDomainValue(value) {
        domainValue = value
        setDomainValue(domainValue)
    }

    function handleDomainAddFormButtonClick() {
        if (!showDomainForm) {
            stateUpdateDomainAddFormShown(true)
            stateUpdateDomainEdit(false)
        }
    }

    function stateUpdateDomainAddFormShown(value){
        showDomainForm = value
        setShowDomainForm(showDomainForm)
    }

    function stateUpdateDomainEdit(value) {
        domainEdit = value
        setDomainEdit(domainEdit)
    }

    async function saveDomain() {
        if (_.isEmpty(domainValue)) {
            toast.error("Please fill domain name field.", {containerId: TOAST_CONTAINER_ID})
            return true
        }

        let id = toast.loading("adding domain", {containerId: TOAST_CONTAINER_ID})

        try {
            await addDomain(StateManager.getToken(), {name: domainValue, isAutoAliasHandle: autoAliasHandle})
            toast.dismiss(id)
            toast.success("Domain added successfully.", {containerId: TOAST_CONTAINER_ID})
            reloadPage()
        } catch (e) {
            toast.dismiss(id)
            console.log(e)
            toast.error(e.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }

    }

    async function showSingleDomainData(event) {
        let domainData = domains[event.target.id]
        stateUpdateCurrentDomainShown(domainData)
        stateUpdateDomainAddFormShown(false)
        stateUpdateDomainValue(domainData.name)
        domainEditAutoAliasHandle = domainData.isAliasAutoHandle
        setDomainEditAutoAliasHandle(domainEditAutoAliasHandle)
        stateUpdateDomainAutoAliasHandle({target: {checked: domainEditAutoAliasHandle}})
        stateUpdateDomainEdit(true)
    }

    async function deleteDomain() {
        toast.loading("deleting domain", {containerId: TOAST_CONTAINER_ID})
        try {
            await deleteDomainOnServer(StateManager.getToken(), {id: currentDomainShown.id})
            toast.dismiss()
            toast.success("domain delete success", {containerId: TOAST_CONTAINER_ID})
            reloadPage()
        } catch (e) {
            toast.dismiss()
            toast.error(e.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }
    }

    async function updateDomain() {

        if (currentDomainShown.isAliasAutoHandle === autoAliasHandle) {
            toast.error("nothing is changed.", {containerId: TOAST_CONTAINER_ID})
            return true
        }

        toast.loading("updating domain", {containerId: TOAST_CONTAINER_ID})
        try {
            await updateDomainOnServer(StateManager.getToken(), {
                id: currentDomainShown.id,
                isAutoAliasHandle: autoAliasHandle
            })
            toast.dismiss()
            toast.success("domain update success", {containerId: TOAST_CONTAINER_ID})
            reloadPage()
        } catch (err) {
            toast.dismiss()
            console.log(err)
            toast.error(err.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }

    }

    async function performSearch() {
        if (_.isEmpty(searchValue)) {
            return true
        }
        toast.loading("searching domains", {containerId: TOAST_CONTAINER_ID})
        try {
            searchPerformed = true
            setSearchPerformed(searchPerformed)
            await startingOperations(searchValue)
            toast.dismiss()
        } catch (e) {
            toast.dismiss()
            toast.error(e.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }

    }


    return (
        <>
            <Helmet>
                <title>Domains</title>
            </Helmet>
            <Header></Header>
            <div className="container-fluid" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3 p-0" style={{border: "1px solid grey"}}>
                        <button onClick={handleDomainAddFormButtonClick} className={Styles.StandardButton}
                                style={{margin: "5px 0 0 0", paddingTop: 0, paddingBottom: 0}}>Add Domain
                        </button>
                        <br></br>
                        <StandardTextInput onChange={stateUpdateSearchValue} styles={{height: "2vh", width: "20vh"}}
                                           placeholder="Search here">
                        </StandardTextInput>&nbsp;

                        {!searchPerformed && (<button onClick={performSearch} className={Styles.StandardButton}
                                                      style={{paddingTop: 0, paddingBottom: 0}}>Search
                        </button>)}

                        {searchPerformed && (<button onClick={reloadPage} className={Styles.StandardButton}
                                                     style={{paddingTop: 0, paddingBottom: 0}}>Clear
                        </button>)}

                        <br></br>
                        <StandardDropdown onChange={stateUpdateSortOption}
                                          selectedValue={StateManager.getDomainSortingKey()} name="Sort Option"
                                          options={sortOptions}></StandardDropdown>&nbsp;
                        <StandardDropdown onChange={stateUpdateSortOrder}
                                          selectedValue={StateManager.getDomainSortingOrder()} name="Sort Order"
                                          options={sortOrders}></StandardDropdown><br></br><br></br>
                        <div>Showing
                            Page <span>{currentPage}</span> of <span>{totalPages}  </span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={previousPage} disabled={!hasPreviousPage}>&lt;Page</button>
                            &nbsp;
                            <button onClick={nextPage} disabled={!hasNextPage}>Page&gt;</button>
                        </div>
                        <ul id="domain-list" className="p-0" style={{listStyle: "none", width: "100%"}}>
                            {!_.isEmpty(domains) && domains.map((domain, key) => (
                                <li onClick={showSingleDomainData} key={key} id={key.toString()} style={{
                                    cursor: "pointer",
                                    margin: "10px 0 10px 0",
                                    border: "1px solid grey"
                                }}>{domain.name}</li>
                            ))}

                            {_.isEmpty(domains) && (<><br></br><span>Nothing to show</span></>)}
                        </ul>
                    </div>
                    <div className="col-md-8 col-lg-8 col-xl-8">
                        {showDomainForm && !domainEdit && (
                            <div style={{width: "100%"}}>
                                <label>example.com = your custom domain, to help you add dns records</label><br></br>
                                <label><strong>Domain:</strong></label>&nbsp;&nbsp;&nbsp;
                                <StandardTextInput styles={{width: "30%"}} onChange={stateUpdateDomainValue}/>
                                <br></br>
                                <label>Auto Handle Alias</label>&nbsp;&nbsp;&nbsp;
                                <input onChange={stateUpdateDomainAutoAliasHandle} type="checkbox"/>
                                <br></br><br></br>
                                <StandardButton onClick={saveDomain} innerText={"Save Domain"}/>
                                &nbsp;&nbsp;&nbsp;
                                {domainEdit && (<><StandardButton onClick={deleteDomain}
                                                                  innerText={"Delete Domain"}/></>)}
                                <br></br><br></br>
                                <h5>DNS Pointing</h5>
                                <label><strong>Record Type:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>MX</span><br></br>
                                <label><strong>Record Name:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>yourcustomdomain.com</span><br></br>
                                <label><strong>Record Priority:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>10</span><br></br>
                                <label><strong>Record Value:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>mail.privmail.com</span><br></br><br></br>
                                <h5>DNS OpenDKIM</h5>
                                <label><strong>Record Type:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>TXT</span><br></br>
                                <label><strong>Record Name:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>id8wixtwy2vg52pk0tfm._domainkey.yourcustomdomain.com</span><br></br>
                                <label><strong>Record Value:</strong></label>&nbsp;&nbsp;&nbsp;
                                <p style={{width: "60%", wordBreak: "break-word"}}>v=DKIM1; h=sha256; k=rsa;
                                    p=MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqMf72cJVEiBdEE5CJQsAneInkh0FEjWbylC8CFlnC2GJCWWu1WkTqR0EFcB7OO3gwLKc/cEUZSy6ThoQLktVz/gZiq7LIJ58FUyEyo/P4luHIERi3Sgl6IxchlKkT6PgcXua52NhyMlPYmGWZKXZ7kEJND+oBEPpVkQKKYu2C70yN1zXhoQuEGi4Qj/J5VY549huGKtNpeMIgl7Cprf5zg99oI/oan60W8NCddZH4acZg2x3wOqXaVW0roqvyyxpD3AybJDosCxSSChnMTziaWm3ZaRZFVeuILrCZMYak59/Gi8Okxd+bMN6QqNTOfXzY/AI3ASvXjA6sROafr5SdstNfF6QOhWnjkQNnQtaWvXHrpUFWsxFCQxJ0A9m3/pdRwfCGg77HSn40WBgoCB2slW8aBNRR1W3VgHaiB0Gi3Hzgq78MprspCuZYuk/BQZR+Is9h7MDdiVoZUFlcvYvjBPuC4x1WRhARK0L8bg2nKLkKnGJ4tZ3Jp7l4zTxPZLRFh8T1tVzCXVQ8JiRRjp0TqPkbupnYz3rS7aVI7D1o+qRK7J6GCsQQWPjLJMPiwpd9y9OLEN5YL+eg3DNxmKWDUyFCqA/M5yVLHslt8jDYDxoWk0UUPi3IQVgVoE6n7fNMP2FqWUNIIZ6e0NRXulTJ+gzCcjkM0/Y0BW7xUw6qb8CAwEAAQ==</p>
                                <h5>DNS SPF</h5>
                                <label><strong>Record Type:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>TXT</span><br></br>
                                <label><strong>Record Name:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>yourcustomdomain.com</span><br></br>
                                <label><strong>Record Value:</strong></label>&nbsp;&nbsp;&nbsp;
                                <span>v=spf1 include:_spf.mail.privmail.com ~all</span><br></br><br></br>
                            </div>)}

                        {domainEdit && !showDomainForm && (<div style={{width: "100%"}}>
                            <label>example.com = your custom domain, to help you add dns records</label><br></br>
                            <label><strong>Domain:</strong></label>&nbsp;&nbsp;&nbsp;
                            <StandardTextInput value={domainValue} isDisabled={true} styles={{width: "30%"}}/>
                            <br></br>
                            <label>Auto Handle Alias</label>&nbsp;&nbsp;&nbsp;
                            <input onChange={stateUpdateDomainAutoAliasHandle}
                                   defaultChecked={domainEditAutoAliasHandle} type="checkbox"/>
                            <br></br><br></br>
                            <StandardButton onClick={updateDomain} innerText={"Update Domain"}/>&nbsp;&nbsp;&nbsp;
                            <StandardButton onClick={deleteDomain} innerText={"Delete Domain"}/>
                            <br></br><br></br>
                            <h5>DNS Pointing</h5>
                            <label><strong>Record Type:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>MX</span><br></br>
                            <label><strong>Record Name:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>yourcustomdomain.com</span><br></br>
                            <label><strong>Record Priority:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>10</span><br></br>
                            <label><strong>Record Value:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>mail.privmail.com</span><br></br><br></br>
                            <h5>DNS OpenDKIM</h5>
                            <label><strong>Record Type:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>TXT</span><br></br>
                            <label><strong>Record Name:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>id8wixtwy2vg52pk0tfm._domainkey.yourcustomdomain.com</span><br></br>
                            <label><strong>Record Value:</strong></label>&nbsp;&nbsp;&nbsp;
                            <p style={{width: "60%", wordBreak: "break-word"}}>v=DKIM1; h=sha256; k=rsa;
                                p=MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqMf72cJVEiBdEE5CJQsAneInkh0FEjWbylC8CFlnC2GJCWWu1WkTqR0EFcB7OO3gwLKc/cEUZSy6ThoQLktVz/gZiq7LIJ58FUyEyo/P4luHIERi3Sgl6IxchlKkT6PgcXua52NhyMlPYmGWZKXZ7kEJND+oBEPpVkQKKYu2C70yN1zXhoQuEGi4Qj/J5VY549huGKtNpeMIgl7Cprf5zg99oI/oan60W8NCddZH4acZg2x3wOqXaVW0roqvyyxpD3AybJDosCxSSChnMTziaWm3ZaRZFVeuILrCZMYak59/Gi8Okxd+bMN6QqNTOfXzY/AI3ASvXjA6sROafr5SdstNfF6QOhWnjkQNnQtaWvXHrpUFWsxFCQxJ0A9m3/pdRwfCGg77HSn40WBgoCB2slW8aBNRR1W3VgHaiB0Gi3Hzgq78MprspCuZYuk/BQZR+Is9h7MDdiVoZUFlcvYvjBPuC4x1WRhARK0L8bg2nKLkKnGJ4tZ3Jp7l4zTxPZLRFh8T1tVzCXVQ8JiRRjp0TqPkbupnYz3rS7aVI7D1o+qRK7J6GCsQQWPjLJMPiwpd9y9OLEN5YL+eg3DNxmKWDUyFCqA/M5yVLHslt8jDYDxoWk0UUPi3IQVgVoE6n7fNMP2FqWUNIIZ6e0NRXulTJ+gzCcjkM0/Y0BW7xUw6qb8CAwEAAQ==</p>
                            <h5>DNS SPF</h5>
                            <label><strong>Record Type:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>TXT</span><br></br>
                            <label><strong>Record Name:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>yourcustomdomain.com</span><br></br>
                            <label><strong>Record Value:</strong></label>&nbsp;&nbsp;&nbsp;
                            <span>v=spf1 include:_spf.mail.privmail.com ~all</span><br></br><br></br>
                        </div>)}
                    </div>
                </div>
            </div>
        </>
    );
}