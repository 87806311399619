import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "../components/navigation";
import Header from "../components/header";
import {Helmet} from "react-helmet-async";
import React, {useEffect, useRef, useState} from "react";
import StandardButton from "../components/basic/StandardButton";
import StandardTextInput from "../components/basic/StandardTextInput";
import Styles from "../styles/main.module.css";
import StandardDropdown from "../components/basic/StandardDropDown";
import StateManager from "../redux/StateManager";
import {ALIAS_SORT_OPTIONS, ALIAS_SORT_ORDER, TOAST_CONTAINER_ID} from "../utils/constants";
import {toast} from "react-toastify";
import _ from "lodash";
import {
    addAliasOnServer,
    deleteAliasOnServer,
    listAliases,
    listDomains
} from "../server-calls";
import {useNavigation} from "../hooks/navigation";

export default function Aliases() {
    const {reloadPage} = useNavigation()
    let [domainId, setDomainId] = useState("");
    let [aliases, setAliases] = useState([]);
    let [sortOptions] = useState(ALIAS_SORT_OPTIONS)
    let [sortOrders] = useState(ALIAS_SORT_ORDER)
    //form values//
    let [aliasValue, setAliasValue] = useState("");
    let [currentPage, setCurrentPage] = useState(1);
    let [totalPages, setTotalPages] = useState(1);
    let [hasNextPage, setHasNextPage] = useState(false);
    let [pageSize] = useState(15);
    let [hasPreviousPage, setHasPreviousPage] = useState(false);
    let [sortOrder, setSortOrder] = useState("asc");
    let [sortOption, setSortOption] = useState("title");
    let [currentAliasShown, setCurrentAliasShown] = useState("");
    let [searchValue, setSearchValue] = useState("");
    let [searchPerformed, setSearchPerformed] = useState(false);
    let [domains, setDomains] = useState([]);
    let [showAliasAddForm, setShowAliasAddForm] = useState(false);
    let [singleAliasShown, setSingleAliasShown] = useState(false);

    function handleDomainDropDownChange(event) {
        domainId = event.target.value;
        setDomainId(domainId)
    }


    function stateUpdateSingleAliasShown(value) {
        singleAliasShown = value
        setSingleAliasShown(singleAliasShown)
    }


    function stateUpdateDomainsData(data) {
        domains = data
        setDomains(domains)
    }

    function stateUpdateSearchValue(value) {
        searchValue = value
        setSearchValue(searchValue)
    }

    function stateUpdateCurrentAliasShown(value) {
        currentAliasShown = value
        setCurrentAliasShown(currentAliasShown)
    }

    function stateUpdatePaginationData(aliasData) {
        hasNextPage = aliasData.hasNext
        hasPreviousPage = aliasData.hasPrevious
        totalPages = aliasData.totalPages
        setHasNextPage(hasNextPage)
        setHasPreviousPage(hasPreviousPage)
        setTotalPages(totalPages)
    }

    async function startingOperations(search = "") {
        let [aliasData, domainsData] = await Promise.all([listAliases(StateManager.getToken(), {
            sortOption: sortOption,
            ascending: sortOrder === "asc",
            pageNumber: currentPage,
            pageSize: pageSize,
            search: search,
            fetchAll: false
        }), listDomains(StateManager.getToken(), {
            sortOption: sortOption,
            ascending: sortOrder === "asc",
            pageNumber: currentPage,
            pageSize: pageSize,
            search: search,
            fetchAll: true
        })])

        console.log(aliasData)

        stateUpdatePaginationData(aliasData)

        let dropDownData = []
        if (!_.isEmpty(domainsData.domains)) {
            domainsData.domains.forEach((domain) => {
                dropDownData.push({
                    value: domain.id,
                    text: domain.name,

                })
            })

            domainId = dropDownData[0].value
            setDomainId(domainId)
        }
        stateUpdateDomainsData(dropDownData)
        setAliases(aliasData.records)

    }

    useEffect(() => {
        toast.promise(startingOperations(), {
            pending: "loading data",
            error: "server error occurred"
        }, {containerId: TOAST_CONTAINER_ID}).catch((error) => {
            console.error(error)
        })

    }, [sortOrder, sortOptions, currentPage]);


    function stateUpdateSortOption(option) {
        StateManager.setDomainSortingKey(option)
        sortOption = option
        setSortOption(sortOption)
    }

    function stateUpdateSortOrder(order) {
        StateManager.setDomainSortingOrder(order)
        sortOrder = order;
        setSortOrder(order);
    }

    function nextPage() {
        currentPage = currentPage + 1;
        setCurrentPage(currentPage);
    }

    function previousPage() {
        currentPage = currentPage - 1;
        setCurrentPage(currentPage);
    }

    function stateUpdateAliasValue(value) {
        aliasValue = value
        setAliasValue(aliasValue)
    }

    function handleAliasAddFormButtonClick() {
        if (!showAliasAddForm) {
            stateUpdateAliasAddForm(true)
            stateUpdateSingleAliasShown(false)
        }
    }

    function stateUpdateAliasAddForm(value) {
        showAliasAddForm = value
        setShowAliasAddForm(showAliasAddForm)
    }

    async function addAlias() {
        if (_.isEmpty(aliasValue)) {
            toast.error("Please fill domain name field.", {containerId: TOAST_CONTAINER_ID})
            return true
        }

        let id = toast.loading("adding alias", {containerId: TOAST_CONTAINER_ID})

        console.log(`adding alias`, domainId)

        try {
            await addAliasOnServer(StateManager.getToken(), {username: aliasValue, domainId: domainId})
            toast.dismiss(id)
            toast.success("Alias added successfully.", {containerId: TOAST_CONTAINER_ID})
            reloadPage()
        } catch (e) {
            toast.dismiss(id)
            console.log(e)
            toast.error(e.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }

    }

    async function showSingleAliasData(event) {
        let aliasData = aliases[event.target.id]
        stateUpdateCurrentAliasShown(aliasData)
        stateUpdateAliasValue(aliasData.email)
        stateUpdateAliasAddForm(false)
        handleDomainDropDownChange({target: {value: aliasData.domainId}})
        stateUpdateSingleAliasShown(true)

    }

    async function deleteAlias() {
        toast.loading("deleting alias", {containerId: TOAST_CONTAINER_ID})
        try {
            await deleteAliasOnServer(StateManager.getToken(), {id: currentAliasShown.id})
            toast.dismiss()
            toast.success("alias delete success", {containerId: TOAST_CONTAINER_ID})
            reloadPage()
        } catch (e) {
            toast.dismiss()
            toast.error(e.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }
    }

    async function performSearch() {
        if (_.isEmpty(searchValue)) {
            return true
        }
        toast.loading("searching aliases", {containerId: TOAST_CONTAINER_ID})
        try {
            searchPerformed = true
            setSearchPerformed(searchPerformed)
            await startingOperations(searchValue)
            toast.dismiss()
        } catch (e) {
            toast.dismiss()
            toast.error(e.response.data.message, {containerId: TOAST_CONTAINER_ID})
        }

    }


    return (
        <>
            <Helmet>
                <title>Domains</title>
            </Helmet>
            <Header></Header>
            <div className="container-fluid" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3 p-0" style={{border: "1px solid grey"}}>
                        <button onClick={handleAliasAddFormButtonClick} className={Styles.StandardButton}
                                style={{margin: "5px 0 0 0", paddingTop: 0, paddingBottom: 0}}>Add Alias
                        </button>
                        <br></br>
                        <StandardTextInput onChange={stateUpdateSearchValue} styles={{height: "2vh", width: "20vh"}}
                                           placeholder="Search here">
                        </StandardTextInput>&nbsp;

                        {!searchPerformed && (<button onClick={performSearch} className={Styles.StandardButton}
                                                      style={{paddingTop: 0, paddingBottom: 0}}>Search
                        </button>)}

                        {searchPerformed && (<button onClick={reloadPage} className={Styles.StandardButton}
                                                     style={{paddingTop: 0, paddingBottom: 0}}>Clear
                        </button>)}

                        <br></br>
                        <StandardDropdown onChange={stateUpdateSortOption}
                                          selectedValue={StateManager.getDomainSortingKey()} name="Sort Option"
                                          options={sortOptions}></StandardDropdown>&nbsp;
                        <StandardDropdown onChange={stateUpdateSortOrder}
                                          selectedValue={StateManager.getDomainSortingOrder()} name="Sort Order"
                                          options={sortOrders}></StandardDropdown><br></br><br></br>
                        <div>Showing
                            Page <span>{currentPage}</span> of <span>{totalPages}  </span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={previousPage} disabled={!hasPreviousPage}>&lt;Page</button>
                            &nbsp;
                            <button onClick={nextPage} disabled={!hasNextPage}>Page&gt;</button>
                        </div>
                        <ul id="domain-list" className="p-0" style={{listStyle: "none", width: "100%"}}>
                            {!_.isEmpty(aliases) && aliases.map((alias, key) => (
                                <li onClick={showSingleAliasData} key={key} id={key.toString()} style={{
                                    cursor: "pointer",
                                    margin: "10px 0 10px 0",
                                    border: "1px solid grey"
                                }}>{alias.email}</li>
                            ))}

                            {_.isEmpty(aliases) && (<><br></br><span>Nothing to show</span></>)}
                        </ul>
                    </div>
                    <div className="col-md-8 col-lg-8 col-xl-8">
                        {showAliasAddForm && !singleAliasShown && (
                            <div style={{width: "100%"}}>
                                <label><strong>Username:</strong></label>&nbsp;&nbsp;&nbsp;
                                <StandardTextInput value={aliasValue} onChange={stateUpdateAliasValue}
                                                   styles={{width: "30%"}}/>
                                <br></br><br></br>
                                <label><strong>Domain:</strong></label>&nbsp;&nbsp;&nbsp;

                                <StandardDropdown onChange={handleDomainDropDownChange}
                                                  options={domains}></StandardDropdown>
                                <br></br><br></br>
                                <StandardButton onClick={addAlias} innerText={"Save Alias"}/>
                            </div>)}

                        {singleAliasShown && !showAliasAddForm && (<div style={{width: "100%"}}>
                            <label><strong>Username:</strong></label>&nbsp;&nbsp;&nbsp;
                            <StandardTextInput value={aliasValue} isDisabled={true} styles={{width: "30%"}}/>
                            <br></br><br></br>
                            <label>Auto Added:</label>&nbsp;&nbsp;&nbsp;
                            <input disabled={true} defaultChecked={currentAliasShown.autoAdded}
                                   type="checkbox"/><br></br><br></br>
                            <label><strong>Domain:</strong></label>&nbsp;&nbsp;&nbsp;
                            <StandardDropdown selectedValue={domainId} isDisabled={true}
                                              options={domains}></StandardDropdown>
                            <br></br><br></br>
                            <StandardButton onClick={deleteAlias} innerText={"Delete Alias"}/>
                        </div>)}
                    </div>
                </div>
            </div>
        </>
    );
}