import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "../components/navigation";
import Header from "../components/header";
import {useEffect, useRef, useState} from "react";
import {convertFileToBase64, parseContactTypeEmail, userIsLoggedIn} from "../utils/utils";
import {encryptMessage} from "../mail/utils";
import StateManager from "../redux/StateManager"
import {fetchPublicKeys, sendMessageToBackend} from "../server-calls/index";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer} from 'react-toastify';
import {useLocation} from "react-router-dom";
import {useNavigation} from "../hooks/navigation";

export default function Compose() {
    const {redirect, reloadPage} = useNavigation()
    let [to, setTo] = useState("");
    let [cc, setCc] = useState("");
    let [bcc, setBcc] = useState("");
    let [toList, setToList] = useState([]);
    let [ccList, setCcList] = useState([]);
    let [bccList, setBccList] = useState([]);
    let [messageBody, setMessageBody] = useState("");
    let [subject, setSubject] = useState("");
    let [isHtml, setIsHtml] = useState(false);
    let [isEncrypt, setIsEncrypt] = useState(false);
    let [attachments, setAttachments] = useState([]);
    let [isBusy, setIsBusy] = useState(false)
    let [receivers, setReceivers] = useState([])
    let replyToRef = useRef(null)
    let subjectRef = useRef(null)
    let ccRef = useRef(null)
    let bccRef = useRef(null)
    let toRef = useRef(null)
    let mainBodyRef = useRef(null)
    let replyToParentRef = useRef(null)

    const location = useLocation();

    let [isReply, setIsReply] = useState(false);
    let [isReplyAll, setIsReplyAll] = useState(false)
    let [isForward, setIsForward] = useState(false)

    const handleIsReplyState = (value) => {
        isReply = value
        setIsReply(isReply)
    }

    const handleIsReplyAllState = (value) => {
        isReplyAll = value
        setIsReplyAll(isReplyAll)
    }

    const handleIsForwardState = (value) => {
        isForward = value
        setIsForward(isForward)
    }

    let [referenceMessageData, setReferenceMessageData] = useState({});

    function handleReferenceMessageData(value) {
        referenceMessageData = value
        setReferenceMessageData(referenceMessageData);
    }

    useEffect(() => {
        userIsLoggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) {
                redirect("/login")
            }
        }).catch((error) => {
            console.error(error)
        })
        const queryParams = new URLSearchParams(location.search);
        handleIsReplyState(queryParams.get('isReply') === 'true')
        handleIsReplyAllState(queryParams.get('isReplyAll') === 'true')
        handleIsForwardState(queryParams.get('isForward') === 'true')
    }, [location.search]);


    useEffect(() => {
        if (isReply) {
            performReplyOperation()
        } else if (isReplyAll) {
            performReplyAllOperation()
        } else if (isForward) {
            performForwardOperation()
        }


    }, [isReply, isReplyAll, isForward, replyToRef]);

    const performReplyOperation = () => {
        handleReferenceMessageData(StateManager.renderedMailData())
        replyToRef.current.value = referenceMessageData.decryptedBody
        replyToRef.current.disabled = true
        replyToRef.current.style.overflowY = 'scroll'
        replyToRef.current.style.overflowX = 'scroll'
        replyToParentRef.current.style.visibility = 'visible'

        if (!referenceMessageData.decryptedSubject.startsWith("Re")) {
            handleOperationSubject(`Re: ${referenceMessageData.decryptedSubject}`)
        } else {
            handleOperationSubject(referenceMessageData.decryptedSubject)
        }

        handleToList(parseToEmails(referenceMessageData.from))
    }

    const performReplyAllOperation = () => {
        performReplyOperation()
        handleCcList(parseToEmails(referenceMessageData.cc))
    }

    const performForwardOperation = () => {
        handleReferenceMessageData(StateManager.renderedMailData())
        mainBodyRef.current.value = `${referenceMessageData.decryptedBody}`
        if (!referenceMessageData.decryptedSubject.startsWith("Fw")) {
            handleOperationSubject(`Fw: ${referenceMessageData.decryptedSubject}`)
        } else {
            handleOperationSubject(referenceMessageData.decryptedSubject)
        }
    }

    function handleOperationSubject(subject) {
        handleSubject(subject)
        subjectRef.current.value = subject
    }

    const parseToEmails = (to) => {
        let emails = []
        if (to.includes(",")) {
            to = to.replaceAll(" ", "")
            let parts = _.split(to, ",")
            parts.forEach((part) => {
                emails.push(parseContactTypeEmail(part))
            })
            return emails
        } else {
            return [parseContactTypeEmail(to)]
        }
    }


    const inputWrapperStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    };

    const tagStyle = {
        display: 'inline-block',
        background: '#e2e6ea',
        borderRadius: '15px',
        padding: '5px 10px',
        margin: '5px',
    };

    const removeButtonStyle = {
        marginLeft: '8px',
        background: 'transparent',
        border: 'none',
        color: '#dc3545',
        cursor: 'pointer'
    };

    const inputStyle = {
        border: 'none',
        outline: 'none',
        flexGrow: 1,
    };


    const handleToChange = (e) => {
        const value = e.target.value;
        setTo(value)
        if (value.includes(",") || value.includes(" ")) {
            let validEmails = handleEmailAddressChange(value)
            handleToList(validEmails)
            setTo("")
        }
    };

    const handleToList = (emails) => {
        let toBePushed = []
        emails.forEach((email) => {
            if (toList.indexOf(email) < 0) {
                toBePushed.push(email)
            }
        })
        if (toBePushed.length > 0) {
            setToList([...toList, ...toBePushed]);
            setReceivers([...receivers, ...toBePushed]);
        }
    }

    const handleCcChange = (e) => {
        const value = e.target.value;
        setCc(value);
        if (value.includes(",") || value.includes(" ")) {
            const validEmails = handleEmailAddressChange(value)
            handleCcList(validEmails)
            setCc("")
        }
    };

    const handleCcList = (emails) => {
        let toBePushed = []
        emails.forEach((email) => {
            if (ccList.indexOf(email) < 0) {
                toBePushed.push(email)
            }
        })
        if (toBePushed.length > 0) {
            setCcList([...ccList, ...toBePushed]);
            setReceivers([...receivers, ...toBePushed]);
        }
    }

    const handleBccChange = (e) => {
        const value = e.target.value;
        setBcc(value);
        if (value.includes(",") || value.includes(" ")) {
            let validEmails = handleEmailAddressChange(value)
            handleBccList(validEmails)
            setBcc("")
        }
    };

    const handleBccList = (emails) => {
        let toBePushed = []
        emails.forEach((email) => {
            if (bccList.indexOf(email) < 0) {
                toBePushed.push(email)
            }
        })
        if (toBePushed.length > 0) {
            setBccList([...bccList, ...toBePushed]);
            setReceivers([...receivers, ...toBePushed]);
        }
    }

    const handleEmailAddressChange = (value) => {
        if (value.includes(',') || value.includes(" ")) {
            const newEmails = value.split(',').map(email => email.trim()).filter(email => email !== '');
            return newEmails.filter(validateEmail);
        }
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleRemoveEmail = (list, setList, index) => {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
    };

    const handleIsBusy = (value) => {
        isBusy = value
        setIsBusy(isBusy)
    }

    const handleFileUpload = async (e) => {
        let files = Array.from(e.target.files)
        let built = []
        for (let i = 0; i < files.length; i++) {
            let type = files[i].type
            if (_.isEmpty(type))
                type = "text/plain"
            built.push({
                name: files[i].name,
                content: await convertFileToBase64(files[i]),
                type
            })
        }
        attachments = built
        setAttachments(attachments);
    };

    const handleEncryptChange = () => {
        setIsEncrypt(!isEncrypt);
    };

    const handleSubject = (value) => {
        subject = value
        setSubject(subject)
    }

    const handleBody = (value) => {
        messageBody = value
        setMessageBody(messageBody)
    }


    const sendMessage = async () => {
        let id = toast.loading("sending message")
        try {

            if (_.isEmpty(toList)) {
                alert("Please enter to emails properly.")
            }

            let backendCallBody = {
                to: toList,
                cc: ccList,
                bcc: bccList,
                message: messageBody,
                subject: subject,
                isHtml,
                attachments,
                isEncrypted: isEncrypt
            }

            if (isReply || isReplyAll) {
                backendCallBody.replyTo = referenceMessageData.messageId
                backendCallBody.replyToUid = referenceMessageData.uid
                if(!_.isEmpty(referenceMessageData.references))
                    backendCallBody.references = referenceMessageData.references
                else
                    backendCallBody.references = ""
                backendCallBody.isAReply = true
            } else if (isForward) {
                backendCallBody.isForward = true
            }

            if (isEncrypt) {
                receivers.push(StateManager.getUserEmail())
                let publicKeys = await fetchPublicKeys(receivers, StateManager.getToken())
                let notFound = publicKeys.notFound
                publicKeys = publicKeys.found
                try {
                    const {body, encryptedSubject} = await encryptMessage({
                        body: messageBody,
                        subject,
                        isHtml,
                        attachments,
                        publicKeys
                    })
                    backendCallBody.message = body
                    backendCallBody.subject = encryptedSubject
                    backendCallBody.isEncrypted = true
                    backendCallBody.isHtml = false
                    backendCallBody.attachments = []
                } catch (error) {
                    toast.dismiss(id)
                    toast.error("error encrypting message")
                    console.error(error)
                }
            }


            await sendMessageToBackend(backendCallBody, StateManager.getToken())
            handleIsBusy(false)
            toast.dismiss(id)
            toast.success("message sent")
            // reloadPage()
        } catch (e) {
            toast.dismiss(id)
            toast.error("error sending message")
            console.log(e)
        }
    }

    return (
        <>
            <Header></Header>
            <div className="container-fluid" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                    <div className="col-md-10 col-lg-10 col-xl-10 p-0">
                        <div style={{marginLeft: "15px", marginTop: "15px"}} className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                {/* To Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">To:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {toList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(toList, setToList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            ref={toRef}
                                            value={to}
                                            onChange={handleToChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* CC Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Cc:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {ccList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(ccList, setCcList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            ref={ccRef}
                                            value={cc}
                                            onChange={handleCcChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* BCC Field */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Bcc:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        {bccList.map((email, index) => (
                                            <div key={index} className="email-tag" style={tagStyle}>
                                                {email}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveEmail(bccList, setBccList, index)}
                                                    style={removeButtonStyle}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                        <input
                                            type="text"
                                            ref={bccRef}
                                            value={bcc}
                                            onChange={handleBccChange}
                                            placeholder="Type email and press comma"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Subject:</label>
                                    <div className="col-sm-6 email-input-wrapper" style={inputWrapperStyle}>
                                        <input
                                            ref={subjectRef}
                                            type="text"
                                            onChange={(e) => handleSubject(e.target.value)}
                                            placeholder="Subject"
                                            style={inputStyle}
                                        />
                                    </div>
                                </div>

                                {/* Message Body */}
                                <div className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Message:</label>
                                    <div style={{padding: 0}} className="col-sm-6">
                                        <textarea
                                            ref={mainBodyRef}
                                            value={messageBody}
                                            onChange={(e) => handleBody(e.target.value)}
                                            rows={15}
                                            style={{width: "100%", padding: '10px', borderRadius: '4px'}}
                                        />
                                    </div>
                                </div>

                                <div ref={replyToParentRef} style={{visibility: "hidden"}}
                                     className="form-group row align-items-center mb-3">
                                    <label className="col-sm-2 col-form-label">Reply To:</label>
                                    <div style={{padding: 0}} className="col-sm-6">
                                                <textarea style={{
                                                    width: '100%',
                                                    padding: '10px',
                                                    borderRadius: '4px'
                                                }} ref={replyToRef} disabled/>
                                    </div>
                                </div>


                                {/* Is HTML Toggle Button */}
                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <button
                                            className={`btn ${isHtml ? 'btn-success' : 'btn-outline-success'}`}
                                            onClick={() => setIsHtml(!isHtml)}
                                            disabled={isBusy}
                                        >
                                            {isHtml ? 'HTML Enabled' : 'Enable HTML'}
                                        </button>
                                    </div>
                                </div>


                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <div className="col-sm-12">
                                            <input
                                                type="checkbox"
                                                checked={isEncrypt}
                                                onChange={handleEncryptChange}
                                            />
                                            <label className="ml-2">Encrypt Message</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <div className="col-sm-12">
                                            <label>Attachments:</label>
                                            <input
                                                type="file"
                                                multiple
                                                onChange={handleFileUpload}
                                            />
                                            {/*<ul className="mt-2">*/}
                                            {/*    {attachments.length > 0 && attachments.map((file, index) => (*/}
                                            {/*        <li key={index}>{file.name}</li>*/}
                                            {/*    ))}*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group row align-items-center mb-3">
                                    <div className="col-sm-6 offset-sm-2">
                                        <button className='btn btn-primary' onClick={sendMessage}
                                                disabled={isBusy}>
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
        ;
}
