import {configureStore, createSlice} from '@reduxjs/toolkit';
import {persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        userEmail: null,
        privateKey: null,
        passphrase: null,
        token: null,
        isLoggedIn: false,
        cacheMailData: {},
        renderedMailData: {},
        mailSortingKey: "date",
        mailSortingOrder: "descending",
        domainSortingKey: "date",
        domainSortingOrder: "descending",
        aliasSortingKey: "date",
        aliasSortingOrder: "descending",
        isSearchRecordsRender: false,
        searchEncryptedWords: [],
        searchPlainText: "",
        searchCurrentPage: 1,
    },
    reducers: {
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setPrivateKey: (state, action) => {
            state.privateKey = action.payload;
        },
        setPassphrase: (state, action) => {
            state.passphrase = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setCacheMailData: (state, action) => {
            state.cacheMailData = action.payload;
        },
        setRenderedMailData: (state, action) => {
            state.renderedMailData = action.payload;
        },

        setMailSortingOrder(state, action) {
            state.mailSortingOrder = action.payload
        },

        setMailSortingKey(state, action) {
            state.mailSortingKey = action.payload
        },

        setDomainSortingOrder(state, action) {
            state.domainSortingOrder = action.payload
        },

        setDomainSortingKey(state, action) {
            state.domainSortingKey = action.payload
        },

        setAliasSortingOrder(state, action) {
            state.aliasSortingOrder = action.payload
        },

        setAliasSortingKey(state, action) {
            state.aliasSortingKey = action.payload
        },

        setIsSearchRecordsRender(state, action) {
            state.isSearchRecordsRender = action.payload
        },
        setSearchEncryptedWords(state, action) {
            state.searchEncryptedWords = action.payload
        },
        setSearchPlainText(state, action){
            state.searchPlainText = action.payload
        },
        setSearchCurrentPage(state, action) {
            state.searchCurrentPage = action.payload
        },

        cleanState: (state) => {
            state.userEmail = null;
            state.privateKey = null;
            state.passphrase = null;
            state.token = null;
            state.isLoggedIn = false;
        }
    }
});

export const {
    setUserEmail,
    setPrivateKey,
    setPassphrase,
    setToken,
    setIsLoggedIn,
    cleanState,
    setCacheMailData,
    setRenderedMailData,
    setMailSortingOrder,
    setMailSortingKey,
    setDomainSortingOrder,
    setDomainSortingKey,
    setAliasSortingOrder,
    setAliasSortingKey,
    setIsSearchRecordsRender,
    setSearchEncryptedWords,
    setSearchPlainText,
    setSearchCurrentPage
} = sessionSlice.actions;


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, sessionSlice.reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

export const persistor = persistStore(store)
